const $ = require('jquery');
require('bootstrap');
require('jquery-backstretch');
global.$ = global.jQuery = $;

window.addEventListener('DOMContentLoaded', function () {

    const passwordInputs = document.querySelectorAll('input[type="password"]');
    passwordInputs.forEach(function (passwordInput) {
        // Create showPassword button
        let showPassword = document.createElement('button');
        showPassword.classList.add('btn-show-password');
        showPassword.innerHTML = '<i class="fa fa-eye"></i>';
        passwordInput.parentElement.appendChild(showPassword);

        // Show password on click on this button
        showPassword.addEventListener('click', function () {
            event.preventDefault();
            let input = this.closest('.form-group').querySelector('input');
            if (input.type === 'password') {
                input.type = 'text';
                showPassword.innerHTML = '<i class="fa fa-eye-slash"></i>';
            } else {
                input.type = 'password';
                showPassword.innerHTML = '<i class="fa fa-eye"></i>';
            }
        })
    });

});